import { eUseScreenState, eUseTranslation } from "@exabytellc/react-utils";
import { primaryColor, primaryTextColor, secondColor } from "./params";
import { eCreateMuiTheme } from "react-e-components";

export const materialTheme = () => {
    const { fontfamily, dir } = eUseTranslation();
    const { sizeBreakpoints, deviceBreakpoints } = eUseScreenState();

    return eCreateMuiTheme({
        dir,
        mode: "light",
        fontSize: 10,
        fontResizeFactor: 5,
        fontFamily: fontfamily,
        primaryColor: primaryColor,
        secondaryColor: secondColor,
        // tertiaryColor,
        // accentColor,
        primaryTextColor: primaryTextColor,
        sizeBreakpoints: sizeBreakpoints,
        deviceBreakpoints: deviceBreakpoints
    });
}