//
import React, { useRef } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Autocomplete from "@mui/material/Autocomplete";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import {
  AccountCircleTwoTone,
  ArrowBackTwoTone,
  ArrowForwardTwoTone,
  CategoryTwoTone,
  FavoriteTwoTone,
  HomeTwoTone,
  LocalShippingTwoTone,
  LoginTwoTone,
  LogoutTwoTone,
  SearchTwoTone,
  ShoppingCartTwoTone,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

import MyImage from "../components/MyImage";
import LogoIcon from "../assets/images/logoIcon.png";
import Logo from "../assets/images/logo.png";
import { useMyCart } from "../models/MyCart";
import { useMyFav } from "../models/MyFav";
import { useMyTags } from "../models/MyTags";
import { useMyClient } from "../models/MyClient";
import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { eList, eUseScreenState , eUseState, eUseTranslation} from "@exabytellc/react-utils";

const AppHeader = ({ title, main, search, onSearchChange }) => {
  const { device } = eUseScreenState();
  const mobile = device === "mobile";
  const tags = useMyTags();
  return (
    <>
      <MobileMainHeader
        mobile={mobile}
        options={tags}
        {...{ main, search, onSearchChange }}
      />
      <MobileSubHeader mobile={mobile} {...{ main, title }} />
      <DesktopHeader
        mobile={mobile}
        options={tags}
        {...{ search, onSearchChange }}
      />
    </>
  );
};
export default AppHeader;

const MobileMainHeader = ({
  mobile,
  main,
  search,
  options,
  onSearchChange,
}) => {
  const { t } = eUseTranslation();
  const { forDevice } = eUseScreenState();
  const navigate = useNavigate();
  const searching = eUseState(false);
  const searchQ = eUseState(search ?? null);

  return (
    mobile &&
    main && (
      <AppBar position="static" variant="dense" color="white">
        {!searching.value && (
          <Toolbar
            variant="dense"
            sx={{
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderBottomColor: "light.main",
              px: 0.5,
            }}
            disableGutters
          >
            <MyImage
              src={LogoIcon}
              alt={t("welcome_greeting")}
              width={40}
              height={40}
            />
            <Typography
              variant="h6"
              textAlign={"start"}
              sx={{ flexGrow: 1, px: 1, fontWeight: "bold" }}
              noWrap
            >
              {t("welcome_greeting")}
            </Typography>
            <Button
              onClick={() => {
                searching.value = true;
              }}
              size="small"
              variant="contained"
              color="light"
              sx={{ p: 0.5 }}
              disableElevation
            >
              <SearchTwoTone fontSize="medium" />
            </Button>
          </Toolbar>
        )}
        {searching.value && (
          <Toolbar
            variant="dense"
            sx={{
              borderBottomWidth: 1,
              borderBottomStyle: "solid",
              borderBottomColor: "light.main",
              px: 0.5,
              py: 0.5,
              height: forDevice(40, 50),
            }}
            disableGutters
          >
            <SearchBar
              t={t}
              navigate={navigate}
              fullWidth={true}
              searching={searching}
              searchQ={searchQ}
              options={options}
              onSearchChange={onSearchChange}
              small={true}
            />
          </Toolbar>
        )}
      </AppBar>
    )
  );
};
const MobileSubHeader = ({ mobile, main, title }) => {
  const { forDir } = eUseTranslation();
  const navigate = useNavigate();

  return (
    mobile &&
    !main && (
      <AppBar position="static" variant="dense" color="white">
        <Toolbar
          variant="dense"
          sx={{
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: "light.main",
            px: 0.5,
            py: 1,
            minHeight: "unset",
          }}
          disableGutters
        >
          <Button
            onClick={() => {
              navigate(window.history.length > 1 ? -1 : "/");
            }}
            size="small"
            variant="contained"
            color="light"
            sx={{ p: 0.5 }}
            disableElevation
          >
            {forDir(
              <ArrowBackTwoTone fontSize="small" />,
              <ArrowForwardTwoTone fontSize="small" />
            )}
          </Button>
          <Typography
            variant="h6"
            textAlign={"center"}
            sx={{ flexGrow: 1, px: 1, pr: 2, fontWeight: "bold" }}
            noWrap
          >
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
    )
  );
};
const DesktopHeader = ({ mobile, search, options, onSearchChange }) => {
  const { t, forDir } = eUseTranslation();
  const { forDevice } = eUseScreenState();
  const navigate = useNavigate();
  const { favCount, clearFav } = useMyFav();
  const { cartCount, clearCart } = useMyCart();
  const searchQ = eUseState(search ?? null);
  const { isClient, logoutClient } = useMyClient();
  const userMenu = eUseState(false);
  const userMenuRef = useRef(null);

  return (
    !mobile && (
      <>
        <AppBar position="static" variant="dense" color="primary">
          <Container maxWidth={"desktop"}>
            <Toolbar
              variant="dense"
              sx={{
                py: 1,
                gap: forDevice(null, 1, 2, 3),
              }}
              disableGutters
            >
              {eList.toArray(
                [
                  // {
                  //   n: t("back"),
                  //   i: forDir(
                  //     <ArrowBackTwoTone fontSize="small" />,
                  //     <ArrowForwardTwoTone fontSize="small" />
                  //   ),
                  //   h: "https://myque.net",
                  // },
                  {
                    n: t("home"),
                    i: <HomeTwoTone />,
                    u: "/",
                  },
                  {
                    n: t("categories"),
                    i: <CategoryTwoTone />,
                    u: "/categories",
                  },
                ],
                (index, item) => (
                  <Button
                    key={`hl-${index}`}
                    {...(item.u
                      ? {
                          to: item.u,
                          component: Link,
                        }
                      : { href: item.h })}
                    size="large"
                    variant="text"
                    color="inherit"
                    disableElevation
                    startIcon={item.i}
                  >
                    {item.n}
                  </Button>
                )
              )}
              <Box sx={{ flexGrow: 1 }} />

              <Tooltip
                title={t("favorites")}
                children={
                  <IconButton
                    component={Link}
                    to={"/favorites"}
                    color="inherit"
                    size={"medium"}
                    children={
                      <Badge
                        badgeContent={favCount()}
                        color="secondary"
                        children={<FavoriteTwoTone />}
                      />
                    }
                  />
                }
              />

              <Tooltip
                title={t("cart")}
                children={
                  <IconButton
                    component={Link}
                    to={"/cart"}
                    color="inherit"
                    size={"medium"}
                    children={
                      <Badge
                        badgeContent={cartCount()}
                        color="secondary"
                        children={<ShoppingCartTwoTone />}
                      />
                    }
                  />
                }
              />

              <Tooltip
                title={t("user")}
                children={
                  <IconButton
                    ref={userMenuRef}
                    onClick={(evt) => (userMenu.value = true)}
                    color="inherit"
                    size={"medium"}
                    children={<AccountCircleTwoTone />}
                  />
                }
              />
              <Menu
                anchorEl={userMenuRef?.current}
                open={userMenu.value}
                onClose={() => (userMenu.value = false)}
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.25,
                      "&:before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "white.main",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: forDir("right", "left"),
                  vertical: "top",
                }}
                anchorOrigin={{
                  horizontal: forDir("right", "left"),
                  vertical: "bottom",
                }}
              >
                {eList.toArray(
                  isClient
                    ? [
                        {
                          n: t("orders"),
                          i: <LocalShippingTwoTone />,
                          u: "/orders",
                        },
                        null,
                        {
                          n: t("logout"),
                          i: <LogoutTwoTone />,
                          o: () => {
                            logoutClient();
                            clearCart();
                            clearFav();
                          },
                          c: "error.main",
                        },
                      ]
                    : [
                        {
                          n: t("login"),
                          i: <LoginTwoTone />,
                          u: "/login",
                        },
                      ],
                  (index, item) => {
                    if (!item) {
                      return <Divider key={`hm-${index}`} />;
                    } else {
                      return (
                        <MenuItem
                          key={`hm-${index}`}
                          sx={{ color: item?.c ?? "inherit" }}
                          {...(item.u && { component: Link, to: item?.u })}
                          onClick={(evt) => {
                            userMenu.value = false;
                            if (item?.o) item?.o(evt);
                          }}
                        >
                          <ListItemIcon
                            sx={{ color: "inherit" }}
                            children={item.i}
                          />
                          <ListItemText
                            sx={{ color: "inherit" }}
                            primary={item.n}
                          />
                        </MenuItem>
                      );
                    }
                  }
                )}
              </Menu>
            </Toolbar>
          </Container>
        </AppBar>
        <AppBar position="static" color="white" sx={{ mb: 1 }}>
          <Container maxWidth={"desktop"}>
            <Toolbar
              variant="dense"
              sx={{
                py: 1.75,
                gap: forDevice(null, 2, 3, 4),
                height: forDevice(null, 65, 75),
              }}
              disableGutters
            >
              <MyImage
                src={Logo}
                alt={t("welcome_greeting")}
                width={"auto"}
                height={"100%"}
                style={{ borderRadius: 4 }}
              />
              <Box height={"100%"} sx={{ flexGrow: 1 }}>
                <SearchBar
                  t={t}
                  navigate={navigate}
                  fullWidth={true}
                  height={"100%"}
                  searchQ={searchQ}
                  options={options}
                  onSearchChange={onSearchChange}
                />
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </>
    )
  );
};

const SearchBar = ({
  t,
  navigate,
  fullWidth,
  height = "auto",
  searching,
  searchQ,
  options,
  onSearchChange,
  small = false,
}) => {
  return (
    <ButtonGroup fullWidth={fullWidth} sx={{ height: height }}>
      <Autocomplete
        freeSolo={true}
        disableClearable={false}
        value={searchQ.value}
        loading={options?.waiting}
        options={options?.data ?? [""]}
        fullWidth={fullWidth}
        sx={{
          height: height,
          borderEndEndRadius: 0,
          borderStartEndRadius: 0,
        }}
        onInputChange={(e, v) => {
          searchQ.value = v;
        }}
        onChange={(e, v) => {
          searchQ.value = v;
          if (onSearchChange) onSearchChange(v);
          else {
            if (v) {
              navigate(`/products/p/1/s/${encodeURIComponent(v)}`);
            }
          }
        }}
        filterOptions={(options, state) => {
          let sq = state.inputValue.toLowerCase();
          if (!sq) return [];
          sq = [sq, ...(sq.split(" ") ?? [])];
          return options
            ?.sort((a, b) => {
              return query(a, sq) - query(b, sq);
            })
            ?.filter((item) => query(item, sq) < 0)
            ?.slice(0, 10);

          function query(value, querys) {
            let p = 0;
            for (let q of querys) {
              if (value.toLowerCase().indexOf(q) > -1) p++;
            }
            return -p;
          }
        }}
        renderInput={(params) => {
          if (params?.InputProps) {
            params.InputProps.sx = {
              ...(params?.InputProps?.sx ?? {}),
              height: height,
              borderEndEndRadius: 0,
              borderStartEndRadius: 0,
            };
          }
          if (params?.inputProps) {
            params.inputProps.style = {
              ...(params?.inputProps?.style ?? {}),
              height: height,
              paddingTop: 0,
              paddingBottom: 0,
            };
            params.inputProps.onKeyDown = (evt) => {
              if (evt.code === "Enter") {
                params.inputProps.onBlur(evt);
                if (onSearchChange) onSearchChange(searchQ.value);
                else {
                  if (searchQ.value) {
                    navigate(
                      `/products/p/1/s/${encodeURIComponent(searchQ.value)}`
                    );
                  }
                }
                if (searching?.value) searching.value = false;
              }
            };
          }
          return (
            <TextField
              {...params}
              placeholder={t("search_hint")}
              size="small"
              sx={{
                height: height,
                borderEndEndRadius: 0,
                borderStartEndRadius: 0,
              }}
              autoFocus={!!searching?.value}
            />
          );
        }}
      />
      <Button
        onClick={() => {
          if (onSearchChange) onSearchChange(searchQ.value);
          else {
            if (searchQ.value) {
              navigate(`/products/p/1/s/${encodeURIComponent(searchQ.value)}`);
            }
          }
          if (searching?.value) searching.value = false;
        }}
        variant="contained"
        size={small ? "medium" : "large"}
        color="primary"
        disableElevation
        fullWidth={false}
        sx={{ height: height, px: small ? 0.5 : 2, py: small ? 0.5 : 1 }}
      >
        <SearchTwoTone />
      </Button>
    </ButtonGroup>
  );
};
