import { CircularProgress, Container, Stack } from "@mui/material";
import AppScaffold from "../layouts/AppScaffold";
import { eUseScreenState } from "@exabytellc/react-utils";

const LoaderPage = () => {
  const { forDevice } = eUseScreenState();

  return (
    <AppScaffold>
      <Container fixed sx={{ my: forDevice(4, null, 8) }}>
        <Stack spacing={forDevice(2, null, 4)} alignItems={"center"}>
          <CircularProgress />
        </Stack>
      </Container>
    </AppScaffold>
  );
};
export default LoaderPage;
