import { Button, Container, Stack, Typography } from "@mui/material";
import AppScaffold from "../layouts/AppScaffold";

import { ArrowBackTwoTone, ArrowForwardTwoTone } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { eUseScreenState, eUseTranslation } from "@exabytellc/react-utils";

const Err404Page = () => {
  const { forDevice } = eUseScreenState();
  const { t, forDir } = eUseTranslation();
  const navigate = useNavigate();

  return (
    <AppScaffold>
      <Container fixed sx={{ my: forDevice(4, null, 8) }}>
        <Stack spacing={forDevice(2, null, 4)} alignItems={"center"}>
          <Typography textAlign={"center"} variant="h3">
            {t("page_not_found")}
          </Typography>
          <Button
            variant="text"
            onClick={() => {
              navigate(window.history.length > 1 ? -1 : "/");
            }}
            color="primary"
            startIcon={forDir(
              <ArrowBackTwoTone fontSize="small" />,
              <ArrowForwardTwoTone fontSize="small" />
            )}
          >
            {t("back")}
          </Button>
        </Stack>
      </Container>
    </AppScaffold>
  );
};
export default Err404Page;
