import {
  RouterProvider,
  createBrowserRouter,
  createHashRouter,
} from "react-router-dom";
import { MuiApp } from "react-e-components";

// models
import { materialTheme } from "./configs/themes";
import { MyFavProvider } from "./models/MyFav";
import { MyCartProvider } from "./models/MyCart";
import { MyTagsProvider } from "./models/MyTags";
import { MyDepsProvider } from "./models/MyDeps";
import { MyClientProvider } from "./models/MyClient";

// pages
import routes from "./routes";
import LoaderPage from "./pages/LoaderPage";
import { MultiProviders, TranslationProvider, eUseTranslation } from "@exabytellc/react-utils";

const App = () => {
  return (
    <MultiProviders
      providers={[
        MyDepsProvider,
        MyTagsProvider,
        MyCartProvider,
        MyFavProvider,
        MyClientProvider,
        TranslationProvider,
      ]}
      render={() => {
        const { locale, dir } = eUseTranslation();
        return (
          <MuiApp locale={locale} dir={dir} theme={materialTheme()}>
            <RouterProvider
              router={createHashRouter(routes)}
              fallbackElement={<LoaderPage />}
            />
          </MuiApp>
        );
      }}
    />
  );
};
export default App;
