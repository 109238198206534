import { useEffect, useLayoutEffect } from "react";
import { useMyClient } from "./MyClient";
import { ApisEB } from "../configs/apis";
import { eCreateContext, eList, eUseApiState, eUseRef, eUseSessionStorageState, eUseState } from "@exabytellc/react-utils";

export const MyCartContext = eCreateContext(() => {
    const { CID, isClient } = useMyClient();
    const cart = eUseSessionStorageState('cart', { fallback: {}, json: true });
    const render = eUseState(0);
    const getCartApi = eUseApiState(ApisEB, "public/ecommerce/clients/cart/get", false);
    const isFirstRun = eUseRef(true);

    // on client login and logout
    useLayoutEffect(() => {
        if (isClient) {
            getCartApi.call({ bodyData: { CID: CID } });
        } else if (!isFirstRun.value) {
            cart.value = {};
        }
        isFirstRun.value = false;
        // eslint-disable-next-line
    }, [CID]);

    // on get carts set in carts
    useEffect(() => {
        if (getCartApi.value?.product && getCartApi.value?.product?.length > 0) {
            for (let prod of getCartApi.value.product) {
                cart.value[`${prod.id}-${prod.client_room_id ?? null}`] = {
                    product: prod, qty: prod.quantity, room: prod.client_room_id ?? null
                }
            }
            render.value += 1;
            cart.save(cart.value);
        }
        // eslint-disable-next-line
    }, [getCartApi.value]);

    // manage cart
    const fromProduct = (product, roomId) => {
        return {
            id: `${product['id']}-${roomId ?? null}`,
            min: parseInt(product?.min_order_quantity ?? 1),
            step: parseInt(product?.step_order_quantity ?? 1),
            max: parseInt(Math.min(product?.max_order_quantity ?? product?.stock ?? 0, product?.stock ?? 0)),
            stock: product?.stock,
        };
    }
    const manageItem = (productId, roomId, product, type) => {
        var { min, step, max } = fromProduct(product, roomId);
        var id = `${productId}-${roomId ?? null}`;
        let item = { ...cart.value[id] ?? { product: product, room: roomId, qty: 0 } };
        item.room = roomId;// = parseInt(item.qty ?? 0);
        item.qty = parseInt(item.qty ?? 0);
        if (type === 1) {
            item.qty += step;
            if (item.qty < min) {
                item.qty = min;
            }
            if (item.qty > max) {
                item.qty = max;
            }
        } else if (type === -1) {
            item.qty -= step;
            if (item.qty > max) {
                item.qty = max;
            }
            if (item.qty < min) {
                item.qty = 0;
            }
        } else {
            item.qty = 0;
        }
        return item;
    }
    const manageCart = (productId, roomId, product, type) => {
        let item = manageItem(productId, roomId, product, type);
        if (item.qty === 0) {
            delete cart.value[`${productId}-${roomId ?? null}`];
        } else {
            cart.value[`${productId}-${roomId ?? null}`] = item;
        }
        render.value += 1;
        cart.save(cart.value);
        // cart.value = { ...cart.value };
    }
    const setCart = (mngApi, productId, roomId, product, type) => {
        if (mngApi && isClient) {
            let item = manageItem(productId, roomId, product, type);
            mngApi.call({
                bodyData: {
                    CID: CID,
                    product_id: productId,
                    client_room_id: roomId ?? null,
                    quantity: item.qty,
                },
                onSuccess: () => {
                    manageCart(productId, roomId, product, type);
                },
                onError: (err) => {
                    console.log("err", err)
                }
            });
        } else {
            manageCart(productId, roomId, product, type);
        }
    }

    // api for components
    const manageApi = (productId, product) => {
        const api = eUseApiState(ApisEB, "public/shop/client/cart/manage", false);
        return {
            api: api,
            get waiting() { return api.waiting },
            addCartItem: (roomId) => addCartItem(productId, roomId, product, api),
            subCartItem: (roomId) => subCartItem(productId, roomId, product, api),
            delCartItem: (roomId) => delCartItem(productId, roomId, product, api),
        }
    }

    // do to cart
    const hasCartItem = (productId) => {
        return !!(Object.values(cart.value)?.find(o => o?.product?.id === productId));
    };
    const getCartItem = (productId, roomId) => {
        var id = `${productId}-${roomId}`;
        return cart.value?.[id] ?? null;
    };
    const getCartItemQty = (productId, roomId) => {
        var id = `${productId}-${roomId}`;
        let item = cart.value?.[id];
        if (item) {
            return parseInt(item.qty)
        } else {
            return null;
        }
    };
    const getCartItemsQty = (productId) => {
        return Object.values(cart.value).reduce((sum, item) => sum + (item.product.id === productId ? parseInt(item.qty) : 0), 0);
    };
    const getCartItemValue = (productId, roomId) => {
        var id = `${productId}-${roomId}`;
        let item = cart.value?.[id];
        if (item) {
            return parseInt(item.qty) * parseFloat(item.product.price);
        } else {
            return null;
        }
    };
    const addCartItem = (productId, roomId, product, mngApi) => {
        setCart(mngApi, productId, roomId, product, 1);
    };
    const subCartItem = (productId, roomId, product, mngApi) => {
        setCart(mngApi, productId, roomId, product, -1);
    };
    const delCartItem = (productId, roomId, product, mngApi) => {
        setCart(mngApi, productId, roomId, product, 0);
    };
    const cartProducts = () => {
        return eList.toArray(cart2(), (sum, item) => item.product);
    };
    const cartCount = () => Object.values(cart.value).reduce((sum, item) => sum + parseInt(item.qty), 0);
    const cartValue = () => Object.values(cart.value).reduce((sum, item) => sum + (parseInt(item.qty) * parseFloat(item.product.price)), 0);
    const cartReset = () => {
        cart.value = {};
        getCartApi.call({ bodyData: { CID: CID } });
    }
    const cart2 = () => {
        let v = {};
        for (let i in cart.value) {
            var p = cart.value[i];
            if (!v?.[p.product?.id]) {
                v[p.product?.id] = p;
            }
        }
        return v;
    };

    const clear = () => {
        cart.value = {};
    };

    return {
        get cart() { return cart.value },
        get cart2() { return cart2() },
        get cartProducts() { return cartProducts() },
        cartCount,
        cartValue,
        cartReset,
        hasCartItem,
        getCartItem,
        getCartItemQty,
        getCartItemsQty,
        getCartItemValue,
        addCartItem,
        subCartItem,
        delCartItem,
        manageApi,
        clearCart: clear
    };
});
export const useMyCart = MyCartContext.Use;
export const MyCartProvider = MyCartContext.Provider;


