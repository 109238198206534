import { useEffect, useLayoutEffect } from "react";
import { useMyClient } from "./MyClient";
import { ApisEB } from "../configs/apis";
import { eCreateContext, eUseApiState, eUseRef, eUseSessionStorageState, eUseState } from "@exabytellc/react-utils";

export const MyFavContext = eCreateContext(() => {
    const { CID, isClient } = useMyClient();
    const fav = eUseSessionStorageState('fav', { fallback: {}, json: true });
    const render = eUseState(0);
    const getFavApi = eUseApiState(ApisEB, "public/shop/client/favorites/get", false);
    const isFirstRun = eUseRef(true);

    // on client login and logout
    useLayoutEffect(() => {
        if (isClient) {
            getFavApi.call({ bodyData: { CID: CID } });
        } else if (!isFirstRun.value) {
            fav.value = {};
        }
        isFirstRun.value = false;
        // eslint-disable-next-line
    }, [CID]);

    // on get carts set in carts
    useEffect(() => {
        if (getFavApi.value?.product && getFavApi.value?.product?.length > 0) {
            for (let prod of getFavApi.value.product) {
                fav.value[prod.id] = prod;
            }
            render.value += 1;
            fav.save(fav.value);
        }
        // eslint-disable-next-line
    }, [getFavApi.value]);

    // manage cart
    const manageFav = (productId, product, favor) => {
        if (favor) {
            fav.value[productId] = product;
        } else {
            delete fav.value[productId];
        }
        render.value += 1;
        fav.save(fav.value);
        //  fav.value = { ...fav.value };
    }
    const setFav = (mngApi, productId, product, favor) => {
        if (mngApi && isClient) {
            mngApi.call({
                bodyData: {
                    CID: CID,
                    product_id: productId,
                    wishlist: favor ? "1" : "0",
                },
                onSuccess: () => {
                    manageFav(productId, product, favor);
                },
                onError: (d) => {
                    console.log("error", d)
                }
            });
        } else {
            manageFav(productId, product, favor);
        }
    }

    const manageApi = (productId, product) => {
        const api = eUseApiState(ApisEB, "public/shop/client/favorites/manage", false);
        return {
            api: api,
            get waiting() { return api.waiting },
            setFavItem: () => setFavItem(productId, product, api),
            delFavItem: () => delFavItem(productId, product, api),
            toggleFavItem: () => toggleFavItem(productId, product, api),
        }
    }

    // do to fav
    const getFavItem = (productId) => {
        return fav.value?.[productId] ?? null;
    };
    const setFavItem = (productId, product, mngApi) => {
        setFav(mngApi, productId, product, true);
    };
    const delFavItem = (productId, product, mngApi) => {
        setFav(mngApi, productId, product, false);
    };
    const toggleFavItem = (productId, product, mngApi) => {
        setFav(mngApi, productId, product, !fav.value.hasOwnProperty(productId));
    };
    const favCount = () => Object.keys(fav.value).length;
    const clear = () => {
        fav.value = {};
    };
    return {
        get fav() { return fav.value },
        get render() { return render.value },
        favCount,
        getFavItem,
        setFavItem,
        delFavItem,
        toggleFavItem,
        manageApi,
        clearFav: clear
    };
});
export const useMyFav = MyFavContext.Use;
export const MyFavProvider = MyFavContext.Provider;