import { eCreateContext, eList, eUseApiState, eUseAsyncState, eUseCookieState } from "@exabytellc/react-utils";
import { ApisEB } from "../configs/apis";
import { ApiCall } from "./MyApis";

export const MyClientContext = eCreateContext(() => {
    const client = eUseCookieState('CID');
    const addresses = eUseApiState(ApisEB, "public/shop/client/addresses/get", !!client.value, {
        bodyData: {
            CID: client.value
        },
    });
    const rooms = eUseApiState(ApisEB, "client/chatrooms/client-rooms/get", !!client.value, {
        bodyData: {
            CID: client.value
        },
    });

    const loginApi = ApiCall("client/clients/access/login");
    const addressAddApi = ApiCall("public/shop/client/addresses/add");
    const addressDeleteApi = ApiCall("public/shop/client/addresses/delete");
    const addressUpdateApi = ApiCall("public/shop/client/addresses/update");
    const cartBatchApi = ApiCall("public/shop/client/cart/batch");
    const favBatchApi = ApiCall("public/shop/client/favorites/batch");
    const checkoutApi = ApiCall("public/shop/client/checkout");
    const orderCancelApi = ApiCall("public/shop/client/orders/cancel");

    const postloginRun = eUseAsyncState(async function ({ CID, cart, fav }) {
        // cart
        if (cart && cart?.length > 0) {
            let cd = await cartBatchApi({
                CID,
                product_id: eList.toArray(cart, (k, v) => k),
                quantity: eList.toArray(cart, (k, v) => v.qty),
            });
            if (!cd) return null;
        }
        // fav
        if (fav && fav?.length > 0) {
            let fd = await favBatchApi({
                CID,
                product_id: eList.toArray(fav, (k, v) => k),
                wishlist: eList.toArray(fav, (k, v) => 1),
            });
            if (!fd) return null;
        }
        // addresses
        addresses.call({ bodyData: { CID: CID } });
        // rooms
        rooms.call({ bodyData: { CID: CID } });
        // return
        return CID;
    });
    const loginRun = eUseAsyncState(async function ({ email, password, cart, fav }) {
        // login
        let ld = await loginApi({ email, password });
        if (!ld) return null;
        const CID = ld?.CID;
        // get data post login
        await postloginRun.run({ CID, cart, fav });
        // return
        return CID;
    });
    const checkoutRun = eUseAsyncState(async ({ payment_type, address_id, payment_transaction_id, }) => {
        // checkout
        let rd = await checkoutApi({
            CID: client.value,
            payment_type,
            address_id,
            payment_transaction_id,
        });
        if (!rd) return null;
        return true;
    });
    const orderCancelRun = eUseAsyncState(async ({ order_id }) => {
        // checkout
        let rd = await orderCancelApi({
            CID: client.value,
            order_id: order_id,
        });
        if (!rd) return null;
        return true;
    });

    const loginCall = async (email, password, cart, fav) => {
        client.value = await loginRun.run({ email, password, cart, fav });
    };
    const postloginCall = async (CID, cart, fav) => {
        client.value = await postloginRun.run({ CID, cart, fav });
    };
    const logoutClient = () => {
        client.value = null;
    }
    const checkoutCall = async (payment_type, address_id, payment_transaction_id, onSuccess) => {
        let r = await checkoutRun.run({ payment_type, address_id, payment_transaction_id });
        if (r && onSuccess) onSuccess();
    }
    const orderCancelCall = async (order_id, onSuccess) => {
        let r = await orderCancelRun.run({ order_id });
        if (r && onSuccess) onSuccess();
    }

    return {
        get CID() { return client.value; },
        get isClient() { return !!client.value; },
        loginCall: loginCall,
        get loginWaiting() { return loginRun.waiting; },
        postloginCall: postloginCall,
        get postloginWaiting() { return postloginCall.waiting; },
        logoutClient: logoutClient,
        get addresses() { return addresses.value; },
        get addressesWaiting() { return addresses.waiting; },
        get rooms() { return rooms.value; },
        get roomsWaiting() { return rooms.waiting; },
        checkoutCall: checkoutCall,
        get checkoutWaiting() { return checkoutRun.waiting; },
        orderCancelCall: orderCancelCall,
        get orderCancelWaiting() { return orderCancelRun.waiting; },
        get addressCRUD() {
            return {
                get: () => addresses.call({ bodyData: { CID: client.value } }),
                add: async (data) => {
                    await addressAddApi({
                        CID: client.value, ...data
                    });
                    addresses.call({ bodyData: { CID: client.value } });
                },
                upd: async (data, id) => {
                    await addressUpdateApi({ CID: client.value, address_id: id, ...data },
                    );
                    addresses.call({ bodyData: { CID: client.value } });
                },
                del: async (id) => {
                    await addressDeleteApi({ CID: client.value, address_id: id }
                    );
                    addresses.call({ bodyData: { CID: client.value } });
                },
            }
        }
    };
});
export const useMyClient = MyClientContext.Use;
export const MyClientProvider = MyClientContext.Provider;