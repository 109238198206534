import { useNavigate, useParams } from "react-router-dom";
import { useLayoutEffect } from "react";
import LoaderPage from "./LoaderPage";
import { useMyCart } from "../models/MyCart";
import { useMyFav } from "../models/MyFav";
import { useMyClient } from "../models/MyClient";

const RemotePage = () => {
  const navigate = useNavigate();
  const { cid } = useParams();
  const { postloginCall } = useMyClient();
  const { cart } = useMyCart();
  const { fav } = useMyFav();

  useLayoutEffect(() => {
    async function login() {
      if (cid) {
        await postloginCall(cid, cart, fav);
      }
      navigate("/", { replace: true });
    }
    login();
  }, []);

  return <LoaderPage />;
};
export default RemotePage;
