import Err404Page from "./pages/Err404Page";
import RemotePage from "./pages/RemotePage";
// import RedirectPage from "./pages/RedirectPage";

const routes = [
  {
    path: "/",
    element: null, //<RedirectPage />,
    children: [
      {
        path: "",
        async lazy() {
          return defaultComponent(
            await import("./pages/products/ProductsPage")
          );
        },
        children: [
          {
            path: "p/:page",
            element: null,
            children: [
              { path: "", element: null },
              {
                path: "c/:categoryId",
                element: null,
                children: [
                  {
                    path: "s/:search",
                    element: null,
                    children: [{ path: "o/:order", element: null }],
                  },
                  { path: "o/:order", element: null },
                ],
              },
              {
                path: "s/:search",
                element: null,
                children: [{ path: "o/:order", element: null }],
              },
              { path: "o/:order", element: null },
            ],
          },
        ],
      },
      {
        path: "/product/:productId",
        async lazy() {
          return defaultComponent(await import("./pages/product/ProductPage"));
        },
      },
      {
        path: "/categories",
        async lazy() {
          return defaultComponent(
            await import("./pages/categories/CategoriesPage")
          );
        },
      },
      {
        path: "/login",
        async lazy() {
          return defaultComponent(await import("./pages/login/LoginPage"));
        },
        children: [
          {
            path: ":nav",
            element: null,
          },
        ],
      },
      {
        path: "/favorites",
        async lazy() {
          return defaultComponent(
            await import("./pages/favorites/FavoritesPage")
          );
        },
      },
      {
        path: "/cart",
        async lazy() {
          return defaultComponent(await import("./pages/cart/CartPage"));
        },
      },
      {
        path: "/checkout",
        async lazy() {
          return defaultComponent(
            await import("./pages/checkout/CheckoutPage")
          );
        },
      },
      {
        path: "/orders",
        async lazy() {
          return defaultComponent(await import("./pages/orders/OrdersPage"));
        },
      },
      {
        path: "/order/:orderId",
        async lazy() {
          return defaultComponent(await import("./pages/order/OrderPage"));
        },
      },
      {
        path: "/remote",
        element: <RemotePage />,
        children: [{ path: ":cid", element: null }],
      },
    ],
  },
  {
    path: "*",
    element: <Err404Page />,
  },
];
export default routes;

function defaultComponent(module, props = {}) {
  const Component = module.default;
  return {
    Component,
    props,
  };
}
