import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./configs/apis";
import "./configs/translation";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

/*
packages used:
    npm install @emotion/cache @emotion/react @emotion/styled @mui/icons-material @mui/lab @mui/material react-router-dom stylis stylis-plugin-rtl@2.0.2 react-multi-carousel react-share react-icons react-e-components react-icons

package.json:
    scrips.start = "set \"FAST_REFRESH=false\" && react-scripts start";
*/
