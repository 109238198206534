//
import React from "react";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";

import { Backdrop, CircularProgress } from "@mui/material";
//

const AppScaffold = ({
  title,
  header,
  main = true,
  contentLoading = false,
  mobileFooter,
  mobileFooterHeight,
  children,
  search,
  onSearchChange,
}) => {
  if (title) document.title = title;

  return (
    <>
      <AppHeader
        title={header ?? title}
        main={main}
        search={search}
        onSearchChange={onSearchChange}
      />
      {children}
      <AppFooter
        main={main}
        mobileFooter={mobileFooter}
        mobileFooterHeight={mobileFooterHeight}
      />
      <Backdrop open={contentLoading}>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
};
export default AppScaffold;
