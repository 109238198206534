import { eApiCaller, eDate, eError, eSuccess } from "@exabytellc/react-utils";

//==============================< eapi state
export const ApisEB = new eApiCaller(
    "https://apis.myque.net/", {
    defaultHeaders: {
        "User-Timezone": eDate.now.timezoneOffset,
    },
    bodyDataType: "formdata",
    responseType: "json",
    responseHandler: (request) => {
        // request error
        if (request.httpCode !== 200) {
            return eError("server_error");
        }
        // api error
        let response = request.response;
        if (response.status !== 200) {
            // any error
            return eError(`${response.status}:${response.statusText}:${response.message}`, response.data);
        }
        // success
        return eSuccess(response.message, response.data);
    }
});
ApisEB.getMedia = function (url) {
    if (url) {
        return `https://qchat-uat.syd1.digitaloceanspaces.com/testing/${url}`;
    }
    return null;
}