import {
  AppBar,
  Badge,
  Box,
  Button,
  Container,
  Stack,
  Toolbar,
  Typography,
  Divider,
  SwipeableDrawer,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MyImage from "../components/MyImage";
import {
  CategoryTwoTone,
  FavoriteTwoTone,
  GridViewTwoTone,
  HomeTwoTone,
  LocalShippingTwoTone,
  LoginTwoTone,
  LogoutTwoTone,
  ShoppingCartTwoTone,
  StorefrontTwoTone,
} from "@mui/icons-material";
import { useMyFav } from "../models/MyFav";
import { useMyCart } from "../models/MyCart";
import Logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { useMyClient } from "../models/MyClient";
import { eList, eUseScreenState, eUseState, eUseTranslation } from "@exabytellc/react-utils";

const AppFooter = ({ main, mobileFooter, mobileFooterHeight = 75 }) => {
  const { device } = eUseScreenState();
  const mobile = device === "mobile";

  return (
    <>
      <MainMobileFooter mobile={mobile} main={main} />
      <SubMobileFooter
        mobile={mobile}
        main={main}
        content={mobileFooter}
        height={mobileFooterHeight}
      />
      <DektopFooter mobile={mobile} main={main} />
    </>
  );
};
export default AppFooter;

const MainMobileFooter = ({ mobile, main }) => {
  const { t, locales, locale, setLocale } = eUseTranslation();
  const { isClient, logoutClient } = useMyClient();
  const { cartCount } = useMyCart();
  const { favCount } = useMyFav();
  const drawer = eUseState(false);

  // const curLoc = () => {
  //   var lk = Object.keys(locales);
  //   var l = lk.indexOf(locale);
  //   lk.splice(l, 1);
  //   return locales[lk[0]];
  // };

  return (
    mobile &&
    main && (
      <>
        <Box height={75} />
        <AppBar
          position="fixed"
          variant="dense"
          color="white"
          sx={{ top: "auto", width: "100%", bottom: 0 }}
        >
          <Toolbar
            variant="dense"
            sx={{
              width: "100%",
              borderTopWidth: 1,
              borderTopStyle: "solid",
              borderTopColor: "light.main",
              px: 0.5,
              pt: 0.5,
            }}
            disableGutters
          >
            <Stack
              direction={"row"}
              spacing={0.5}
              sx={{
                width: "100%",
              }}
            >
              {eList.toArray(
                [
                  {
                    n: <Typography variant="caption" children={t("home")} />,
                    i: <StorefrontTwoTone />,
                    u: "/",
                  },
                  {
                    n: <Typography variant="caption" children={t("cart")} />,
                    i: (
                      <Badge
                        badgeContent={cartCount()}
                        color="secondary"
                        children={<ShoppingCartTwoTone />}
                      />
                    ),
                    u: "/cart",
                  },
                  {
                    n: (
                      <Typography variant="caption" children={t("favorites")} />
                    ),
                    i: (
                      <Badge
                        badgeContent={favCount()}
                        color="secondary"
                        children={<FavoriteTwoTone />}
                      />
                    ),
                    u: "/favorites",
                  },
                  {
                    n: <Typography variant="caption" children={t("more")} />,
                    i: <GridViewTwoTone />,
                    o: () => (drawer.value = true),
                  },
                ],
                (index, item) => (
                  <Box
                    key={`mf-${index}`}
                    sx={{
                      ...(item.sx ?? {}),
                      flexGrow: 1,
                      flexShrink: 0,
                      flexBasis: 0,
                    }}
                  >
                    {!item.c && (
                      <Button
                        {...(item?.u
                          ? { component: Link, to: item?.u }
                          : { onClick: item?.o })}
                        size="large"
                        variant="text"
                        color="inherit"
                        disableElevation
                        fullWidth
                        sx={{ py: 1, px: 0.5 }}
                      >
                        <Stack
                          direction={"column"}
                          spacing={0.1}
                          alignItems={"center"}
                        >
                          {item.i}
                          <Typography noWrap>{item.n}</Typography>
                        </Stack>
                      </Button>
                    )}
                    {item.c}
                  </Box>
                )
              )}
            </Stack>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          anchor={"right"}
          open={drawer.value}
          onOpen={() => (drawer.value = true)}
          onClose={() => (drawer.value = false)}
          ModalProps={{
            keepMounted: false,
          }}
        >
          <Stack
            spacing={1}
            direction="column"
            sx={{ width: 225, color: "primary" }}
            role="presentation"
          >
            <MyImage
              src={Logo}
              alt={t("welcome_greeting")}
              width={"100%"}
              height={70}
              imgWidth={"100%"}
              imgHeight={"100%"}
              sx={{ padding: 1 }}
              style={{ borderRadius: 4 }}
            />

            {eList.toArray(
              [
                {
                  n: t("home"),
                  i: <HomeTwoTone />,
                  u: "/",
                },
                {
                  n: t("categories"),
                  i: <CategoryTwoTone />,
                  u: "/categories",
                },
                null,
                ...(isClient
                  ? [
                      {
                        n: t("orders"),
                        i: <LocalShippingTwoTone />,
                        u: "/orders",
                      },
                      {
                        n: t("logout"),
                        i: <LogoutTwoTone />,
                        o: () => logoutClient(),
                        c: "error.main",
                      },
                    ]
                  : [
                      {
                        n: t("login"),
                        i: <LoginTwoTone />,
                        u: "/login",
                      },
                    ]),
                null,
              ],
              (index, item) => {
                if (!item) {
                  return <Divider key={`fl-${index}`} />;
                } else {
                  return (
                    <ListItem
                      key={`fl-${index}`}
                      disablePadding
                      sx={{ color: item?.c ?? "inherit" }}
                    >
                      <ListItemButton
                        {...(item.u
                          ? { component: Link, to: item?.u }
                          : { onClick: item?.o })}
                      >
                        <ListItemIcon
                          sx={{ color: "inherit" }}
                          children={item.i}
                        />
                        <ListItemText
                          sx={{ color: "inherit" }}
                          primary={item.n}
                        />
                      </ListItemButton>
                    </ListItem>
                  );
                }
              }
            )}
           
          </Stack>
        </SwipeableDrawer>
      </>
    )
  );
};
const SubMobileFooter = ({ mobile, main, content, height = 75 }) => {
  return (
    mobile &&
    !main &&
    content && (
      <>
        <Box height={height} />
        <AppBar
          position="fixed"
          variant="dense"
          color="white"
          sx={{ top: "auto", width: "100%", bottom: 0 }}
        >
          <Toolbar
            variant="dense"
            sx={{
              width: "100%",
              borderTopWidth: 1,
              borderTopStyle: "solid",
              borderTopColor: "light.main",
              px: 0.5,
              pt: 0.5,
            }}
            disableGutters
          >
            {content}
          </Toolbar>
        </AppBar>
      </>
    )
  );
};
const DektopFooter = ({ mobile, main }) => {
  const { t } = eUseTranslation();

  return (
    !mobile && (
      <>
        <AppBar position="static" variant="dense" color="primary">
          <Container maxWidth={"desktop"}>
            <Toolbar
              variant="dense"
              sx={{
                width: "100%",
                py: 4,
                px: 3,
                minHeight: "unset",
              }}
            >
              <Typography
                variant="h6"
                textAlign={"center"}
                sx={{
                  width: "100%",
                }}
              >
                {t("footer_desc")}
              </Typography>
            </Toolbar>
          </Container>
        </AppBar>
      </>
    )
  );
};
