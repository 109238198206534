import { useEffect, useState } from "react";
import { ApisEB } from "../configs/apis";
import { eList } from "@exabytellc/react-utils";

export const ApiStoredState = (
    url,
    {
        bodyData,
        namespace = "",
        autoNS = true,
        autoCall = true,
        onSuccess = ({ message, data }) => { },
        onError = ({ message, data }) => { }
    } = {}) => {
    const getNamespace = (namespace, bodyData) => {
        if (!autoNS) {
            return namespace;
        } else {
            return `${namespace}:${eList.objToUrlData(bodyData ?? {})}`;
        }
    }

    const [getN, setN] = useState(getNamespace(namespace, bodyData))
    const [getV, setV] = useState(ApisEB.checkStored(url, getN)?.data ?? null);
    const [getW, setW] = useState(getV === null && autoCall);

    const callApi = async (bd, {
        ns = namespace,
        onSuccess = ({ message, data }) => { },
        onError = ({ message, data }) => { }
    } = {}) => {
        setW(true);
        setV(null);
        setTimeout(async () => {
            setN(getNamespace(ns, bd))
            let r = await ApisEB.callStored(url, {
                method: "POST",
                bodyData: bd,
                namespace: getN,
            });
            setW(false);
            if (r.success === false) {
                console.log(r.message, r.data);
                if (onError) onError(r);
                else {
                    alert(r.message);
                }
            } else {
                setV(r.data);
                if (onSuccess) onSuccess(r);
            }
        }, 10);
    }

    // auto call api
    useEffect(() => {
        if (autoCall === true && !getV) {
            callApi(bodyData, { ns: namespace, onSuccess, onError });
        }
        // eslint-disable-next-line
    }, []);

    return {
        get value() {
            return getV;
        },
        get waiting() {
            return getW;
        },
        get namespace() {
            return getN;
        },
        callApi: callApi,
        recallApi: () => callApi(bodyData, { ns: namespace, onSuccess, onError })
    };
};
export const ApiState = (
    url,
    {
        bodyData,
        autoCall = true,
        onSuccess = ({ message, data }) => { },
        onError = ({ message, data }) => { }
    } = {}
) => {
    const [getW, setW] = useState(autoCall);
    const [getV, setV] = useState(null);

    const callApi = async (bd, { onSuccess = ({ message, data }) => { }, onError = ({ message, data }) => { } } = {}) => {
        setW(true);
        setV(null);
        setTimeout(async () => {
            let r = await ApisEB.call(url, {
                method: "POST",
                bodyData: bd,
            });
            setW(false);
            if (r.success === false) {
                console.log(r.message, r.data);
                if (onError) onError(r);
                else {
                    alert(r.message);
                }
            } else {
                setV(r.data);
                if (onSuccess) onSuccess(r);
            }
        }, 10);
    }

    // auto call api
    useEffect(() => {
        if (autoCall === true && !getV) {
            callApi(bodyData, { onSuccess, onError });
        }
        // eslint-disable-next-line
    }, []);

    return {
        get value() {
            return getV;
        },
        get waiting() {
            return getW;
        },
        callApi: callApi,
        recallApi: () => callApi(bodyData, { onSuccess, onError })
    };
};
export const ApiCall = (url) => {
    return async (bd, props) => {
        let r = await ApisEB.call(url, {
            method: "POST",
            bodyData: bd,
            ...props
        });
        if (r.success === false) {
            console.log(r.message, r.data);
            alert(r.message);
            return false;
        } else {
            return r.data;
        }
    }
};
